import { ErrorsComponent } from 'src/app/shared/errors/errors.component';
import { UsersComponent } from './pages/users/components/users/users.component';
import { isAdmin } from './shared/guards/isAdmin.guard';
import { ClientComponent } from './pages/client/components/client/client.component';
import { AddAttendanceComponent } from './pages/attendance/components/add-attendance/add-attendance.component';
import { AttendanceComponent } from './pages/attendance/components/attendance/attendance.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { ProfileComponent } from './pages/profile/components/profile.component';
import { QueryComponent } from './pages/query/components/query.component';
import { LoginComponent } from './pages/login/components/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateClientComponent } from './pages/client/components/create-client/create-client.component';
import { CreateUsersComponent } from './pages/users/components/create-users/create-users.component';
import { ReportComponent } from './pages/report/components/report.component';
import { MessagesComponent } from './pages/messages/messages.component';


export const routes: Routes = [
  {
    path:'',
    component: QueryComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'usuarios',
    component: UsersComponent,
    canActivate:[isAdmin]
   
  },
  {
    path:'usuarios/novousuario',
    component: CreateUsersComponent,
    canActivate:[isAdmin]
   
  },
  {
    path:'cliente',
    component: ClientComponent,
    canActivate:[AuthGuard]
   
  },
  {
    path:'cliente/novoCliente', 
    component:CreateClientComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'relatorio', 
    component:ReportComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'perfil', 
    component:ProfileComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'atendimento', 
    component:AttendanceComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'atendimento/novoatendimento', 
    component:AddAttendanceComponent,
    canActivate:[AuthGuard]
  },
  {
    path:'',
    children:[
      {path:'', redirectTo:'login',pathMatch:'full'},
      {path:'login', component:LoginComponent}
    ]
  },
  {
    path:'mensagem',
    component:MessagesComponent,
    canActivate:[AuthGuard]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
