import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {MatTableModule} from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { LayoutModule } from '@angular/cdk/layout';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule, MatSidenavModule, MatToolbarModule, MatListModule, MatNativeDateModule } from '@angular/material';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { FlashMessagesModule } from 'flash-messages-angular';


import { AppComponent } from './app.component';
import { AddAttendanceComponent } from './pages/attendance/components/add-attendance/add-attendance.component';
import { AttendanceComponent } from './pages/attendance/components/attendance/attendance.component';
import { LoginComponent } from './pages/login/components/login.component';
import { ProfileComponent } from './pages/profile/components/profile.component';
import { routes } from 'src/app/app-routing.module';
import { RouterModule } from '@angular/router';
import { QueryComponent } from './pages/query/components/query.component';
import { FormsModule } from '@angular/forms';
import { HttpInterceptorProviders } from '../app/shared/http-interceptors/intercepto';
import { ClientComponent } from './pages/client/components/client/client.component';
import { CreateClientComponent } from './pages/client/components/create-client/create-client.component';
import { UsersComponent } from './pages/users/components/users/users.component';
import { CreateUsersComponent } from './pages/users/components/create-users/create-users.component';
import { NavbarComponent } from './pages/navbar/navbar.component';
import { ErrorsComponent } from './shared/errors/errors.component';
import { ReportComponent } from './pages/report/components/report.component';
import { MessagesComponent } from './pages/messages/messages.component';
import { LoadingComponent } from './pages/loading/loading.component';
import { AuthInterceptor } from './shared/http-interceptors/auth-interceptor';




@NgModule({
  declarations: [
    AppComponent,
    AddAttendanceComponent,
    AttendanceComponent,
    LoginComponent,
    ProfileComponent,
    QueryComponent,
    ClientComponent,
    CreateClientComponent,
    UsersComponent,
    CreateUsersComponent,
    NavbarComponent,
    ErrorsComponent,
    ReportComponent,
    MessagesComponent,
    LoadingComponent,


  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes),
    FormsModule,
    HttpClientModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatSelectModule,
    LayoutModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatRadioModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    FlashMessagesModule.forRoot(),


  ],
  providers: [
    HttpInterceptorProviders,
    AuthInterceptor
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
