import { element } from 'protractor';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClientsService } from '../../providers/clients.service';
import { Console, info } from 'console';
import { FormControl, Validators } from '@angular/forms';
import { LoadingService } from 'src/app/shared/loading/loading.service';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.css']
})
export class CreateClientComponent implements OnInit {

  razaoFormControl = new FormControl('', [Validators.required]);
  emailFormControl = new FormControl('', [Validators.required, Validators.email])
  celularFormControl = new FormControl('', [Validators.required])
  cidadeFormControl = new FormControl('', [Validators.required])
  bairroFormControl = new FormControl('', [Validators.required])
  estadoFormControl = new FormControl('', [Validators.required])
  logradouroFormControl = new FormControl('', [Validators.required])


  public resultApiCnpj: any
  public resultCep: any
  public loading: any

  public infoPais: any
  public infoRede: any

  public listagens: any

  public representante: any

  cnpj = {
    cnpj: ''
  }


  cliente = {
    pais_cli_id: '',
    rede_social_cli_id: '',
    logradouro_cli: '',
    numero_cli: '',
    ddi_cli: '',
    complemento_cli: '',
    cep_cli: '',
    estado_cli: '',
    cidade_cli: '',
    bairro_cli: '',
    email_cli: '',
    telefone_cli: '',
    celular_cli: '',
    rede_social_user_cli: '',
    cnpj_cli: '',
    razao_cli: '',
    usuario_responsavel_id: ''
  }

  public selectRepresentante: boolean = false
  idRede: any;
  nomeRede: any;
  idPais: any;
  nomePais: any;
  infoRep: any;
  idRep: any;
  nomeRep: string;


  constructor(
    private clientsService: ClientsService,
    private router: Router,
    private loadingService: LoadingService,
  ) { }


  async ngOnInit() {
    this.loadingService.loadingState(true)

    var usuarioLogado = window.localStorage.getItem('usuario')
    this.clientsService.checkToken(usuarioLogado)
    try {

      this.listagens = await this.clientsService.listagemCliente()

      await this.valoresListagem()

    } catch (error) {
      // console.log(error)

    }

    this.loadingService.loadingState(false)

  }


  async valoresListagem() {
    try {
      if (this.listagens.deptoUsuario == 2) this.selectRepresentante = true

      this.infoRede = await this.listagens.redes.filter(obj => obj.id == this.cliente.rede_social_cli_id)
      this.infoRede = this.infoRede[0]
      if (this.infoRede) {
        this.idRede = this.infoRede.id
        this.nomeRede = this.infoRede.rede_social_rede
      }


      this.infoPais = await this.listagens.paises.filter(obj => obj.id == this.cliente.pais_cli_id)
      this.infoPais = await this.infoPais[0]
      if (this.infoPais) {
        this.cliente.ddi_cli = await this.infoPais.ddi_pa
        this.idPais = this.infoPais.id
        this.nomePais = this.infoPais.pais_pa
      }



      this.infoRep = await this.listagens.representantes.filter(obj => obj.id == this.cliente.usuario_responsavel_id)
      this.infoRep = this.infoRep[0]
      if (this.infoRep) {
        this.idRep = this.infoRep.id
        this.nomeRep = this.infoRep.first_name + ' ' + this.infoRep.last_name
      }


    } catch (e) {
      // console.log(e)
      console.log('ERRO AO RETORNAR INFORMAÇÕES DAS LISTAS...')
    }
  }


  async CnpjApi() {
    if (this.cliente.cnpj_cli.length == 14) {
      this.loadingService.loadingState(true)
      this.resultApiCnpj = await this.clientsService.buscaCnpj(this.cliente.cnpj_cli, 'API')
      this.cliente.razao_cli = this.resultApiCnpj.razao_social.toLowerCase()
      this.cliente.razao_cli = this.cliente.razao_cli
        .replace(/(^\w{1})|(\s+\w{1})/g, letra => letra.toUpperCase())
        .replace('Ltda', 'LTDA')

      let validaCnpjMEI = this.cliente.razao_cli.split(' ')
      for (let index of validaCnpjMEI) {
        if (index.match(/[0-9]+/) && index.length == 11) {

          this.cliente.razao_cli = this.cliente.razao_cli.replace(/[0-9]+/, '')
        }
      }
      if (this.resultApiCnpj.cep) {
        this.cliente.cep_cli = this.resultApiCnpj.cep
        this.cliente.numero_cli = this.resultApiCnpj.numero
        this.ApiCep()
      }
      this.loadingService.loadingState(false)


    }
  }


  async ApiCep() {
    try {
      if (this.cliente.cep_cli.length == 8) {
        this.loadingService.loadingState(true, "Buscando infomações de endereço..")
        let link = 'https://ws.apicep.com/cep.json?code=' + this.cliente.cep_cli

        this.resultCep = await this.clientsService.buscaCep(link)

        if (this.resultCep.status == 200) {
          this.cliente.estado_cli = this.resultCep.state
          this.cliente.cidade_cli = this.resultCep.city
          this.cliente.logradouro_cli = this.resultCep.address
          this.cliente.bairro_cli = this.resultCep.district
        } else {
          this.resultCep = ''
          link = 'https://brasilapi.com.br/api/cep/v1/' + this.cliente.cep_cli
          this.resultCep = await this.clientsService.buscaCep(link)

          if (this.resultCep.cep != '') {
            this.cliente.estado_cli = this.resultCep.state
            this.cliente.cidade_cli = this.resultCep.city
            this.cliente.logradouro_cli = this.resultCep.street
            this.cliente.bairro_cli = this.resultCep.neighborhood
          } else {
            this.resultCep = ''
            link = `https://viacep.com.br/ws/${this.cliente.cep_cli}/json/`
            this.resultCep = await this.clientsService.buscaCep(link)

            if (this.resultCep != '') {
              this.cliente.estado_cli = this.resultCep.uf
              this.cliente.cidade_cli = this.resultCep.localidade
              this.cliente.logradouro_cli = this.resultCep.logradouro
              this.cliente.bairro_cli = this.resultCep.bairro
              this.resultCep = ''
            }
          }
        }
      }

    } catch (error) {
      console.log(error, 'error:cep nao existe')
    }
    this.loadingService.loadingState(false)

  }


  async inserirCliente() {
    this.loadingService.loadingState(true)

    try {
      if (this.cliente.razao_cli.length == 0) throw new Error(`O campo Razão Social está vazio`);
      else if (this.cliente.email_cli.length == 0) throw new Error(`O campo E-mail está vazio`);
      else if (this.cliente.celular_cli.length == 0) throw new Error(`O campo Celular está vazio`);
      else if (this.cliente.cep_cli.length == 0) throw new Error(`O campo CEP está vazio`);
      else if (this.cliente.cidade_cli.length == 0) throw new Error(`O campo Cidade está vazio`);
      else if (this.cliente.estado_cli.length == 0) throw new Error(`O campo Estado está vazio`);
      else if (this.cliente.logradouro_cli.length == 0) throw new Error(`O campo Logradouro está vazio`);
      else {
        await this.clientsService.inserirCliente(this.cliente)
        window.location.href = '/cliente'
      }

    } catch (error) {
      console.log('ERRO AO INSERIR CLIENTE!')

    }
    this.loadingService.loadingState(false)

  }

}
