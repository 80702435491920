import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public usuario
  constructor(private http:HttpClient) { }

  async checkToken(usuario){
    const checkToken = await this.http.post<any>(`${environment.api}/checkToken`, {usuario}).toPromise()
  }

  async infoUsuario(usuario){
    this.usuario = await this.http.post<any>(`${environment.api}/perfil`, {usuario}).toPromise()
    return this.usuario
  }

  async ajusteUsuario(attUsuario:any){
    this.usuario = await this.http.post<any>(`${environment.api}/attPerfil`, attUsuario).toPromise()
    return JSON.stringify(this.usuario)
  }

}
