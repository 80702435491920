import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public isLoading = new EventEmitter<any>()
  
  constructor() { }



  loadingState(state:boolean,msg?:string){
    if(state) this.isLoading.emit({tipo:true,msg})
    else setTimeout(() => this.isLoading.emit(false), 500)
  }


}


