import { QueryService } from './../../query/providers/query.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/shared/loading/loading.service';

@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.css']
})
export class QueryComponent implements OnInit {

  public consulta = {
    tipo: 'CPF/CNPJ',
    conteudo: ''
  }
  public cnjp = {
    nSerie: '',
    novoCNPJ: ''
  }

  public resultado: any[]
  public consultado: boolean
  public tipoConsulta: string
  public localizado: boolean
  public ativar: boolean


  constructor(
    private queryService: QueryService,
    private router: Router,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    var usuarioLogado = window.localStorage.getItem('usuario')
    this.queryService.checkToken(usuarioLogado)

  }




  async onSubmit() {
    this.loadingService.loadingState(true, "Consultando...")

    try {
      this.ativar = false
      this.resultado = await this.queryService.consultaCpfCnpj(this.consulta)

      for(let retorno in this.resultado[0]){
        this.resultado[0][retorno].mostrarAtd = false
      }

      if (this.resultado.length == 0) return this.localizado = false

      this.localizado = true


    } catch (error) {
      this.consultado = false

    }

    this.loadingService.loadingState(false)

  }

  async RedCliente(id: string) {
    localStorage.setItem('id_cli', id)
    this.router.navigate(['/cliente'])
  }

  async RedAtendimento(id: string) {
    localStorage.setItem('id_aten', id)
    this.router.navigate(['/atendimento'])
  }

  async CriarAtenRed(id){
    localStorage.setItem('id_cli', id)
    this.router.navigate(['/atendimento/novoatendimento'])
  }


  async VerAtendimentos(id:string) {
    for(let retorno in this.resultado[0]){
      if(this.resultado[0][retorno].id == id && this.resultado[0][retorno].mostrarAtd == false) this.resultado[0][retorno].mostrarAtd = true
      else this.resultado[0][retorno].mostrarAtd = false
    }
  }


}



