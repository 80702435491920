import { ErrorsComponent } from 'src/app/shared/errors/errors.component';
import { Component, OnInit } from '@angular/core';
import { AttendanceService } from './pages/attendance/providers/attendance.service';
import { LoginService } from './pages/login/providers/login.service';
import { LoadingService } from './shared/loading/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  public title = 'texafront';


  public mostrarMenu: boolean = false
  public mostrarError: boolean = false
  public state: boolean = false

  public loading: boolean = false
  public msgLoading: string

  public mostrarMensagem: boolean = false
  public mensagem: string



  constructor(
    private loginService: LoginService,
    private loadingService: LoadingService

  ) { }


  async ngOnInit() {
    this.loginService.mostrarMenuEmitter.subscribe(
      (mostrar: boolean) => this.mostrarMenu = mostrar

    )

    this.loadingService.isLoading.subscribe(
      (state: any) => {
        this.loading = state.tipo
        if (state.msg != undefined) {
          this.msgLoading = state.msg
        }else{
          this.msgLoading = "Carregando..."
        }
      }
    )

  }


  abrirMensagem(mensagem: any) {
    this.mostrarMensagem = false

    if (mensagem.length == 0 || typeof mensagem != 'string') this.mensagem = 'ERRO DESCONHECIDO...'
    else this.mensagem = mensagem

    this.mostrarMensagem = true
    setTimeout(() => this.fecharMensagem(), 10000)
  }


  fecharMensagem() {
    this.mostrarMensagem = false
  }





}
