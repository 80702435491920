import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UsersService } from '../../providers/users.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { LoadingService } from 'src/app/shared/loading/loading.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-create-users',
  templateUrl: './create-users.component.html',
  styleUrls: ['./create-users.component.css']
})


export class CreateUsersComponent implements OnInit {
  @ViewChild("id") id: ElementRef
  @ViewChild("username") username: ElementRef
  @ViewChild("password") password: ElementRef
  @ViewChild("is_superuser") is_superuser: ElementRef
  @ViewChild("first_name") first_name: ElementRef
  @ViewChild("last_name") last_name: ElementRef
  @ViewChild("cpf") cpf: ElementRef
  @ViewChild("email") email: ElementRef
  @ViewChild("departamento_dep_id") departamento_dep_id: ElementRef
  public usuarioLogado = window.localStorage.getItem('usuario')
  public usuario = {
    username: '',
    password: '',
    is_superuser: '',
    first_name: '',
    last_name: '',
    cpf: '',
    email: '',
    departamento_dep_id: ''
  }

  public departamento

  matcher = new MyErrorStateMatcher();

  userFormControl = new FormControl('', [Validators.required]);
  emailFormControl = new FormControl('', [Validators.required]);
  nomeFormControl = new FormControl('', [Validators.required]);
  senhaFormControl = new FormControl('', [Validators.required]);
  sobrenomeFormControl = new FormControl('', [Validators.required]);
  cpfFormControl = new FormControl('', [Validators.required]);


  constructor(
    private usersService: UsersService,
    private loadingService: LoadingService
  ) { }


  async ngOnInit() {
    await this.usersService.checkToken(this.usuarioLogado !== '')
    this.departamento = await this.usersService.listarDepartamentos()
    this.departamento = this.departamento[0]


  }


  async salvar() {
    this.loadingService.loadingState(true, "Criando Usuario...")

    try {

      this.cpf.nativeElement.value = this.cpf.nativeElement.value.replace(/\D+/g, '')

      this.usuario = {
        username: this.username.nativeElement.value,
        password: this.password.nativeElement.value,
        is_superuser: (<HTMLInputElement>document.querySelector('input[name="cliente.is_superuser"]:checked')).value,
        first_name: this.first_name.nativeElement.value,
        last_name: this.last_name.nativeElement.value,
        cpf: this.cpf.nativeElement.value,
        email: this.email.nativeElement.value,
        departamento_dep_id: (<HTMLInputElement>document.querySelector('input[id="idSel"]')).value
      }



      for (let info in this.usuario) {
        if (this.usuario[info].length == 0) throw new Error(`O campo ${info} está vazio`);
      }


      let retorno = await this.usersService.novoUsuario(this.usuarioLogado, this.usuario)

      window.location.href = '/usuarios'

    } catch (e) {

      console.log('PREENCHA TODOS OS CAMPOS CORRETAMENTE!')

    }
    this.loadingService.loadingState(false)

  }

}
