import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {

  public mostrarErroEmitter = new EventEmitter<any>()
  public mostrarSucessoEmitter = new EventEmitter<any>()

  constructor() {}

  abrirMensagem(mensagem:any, tipo:string){
    if(tipo == 'success') this.mostrarSucessoEmitter.emit(mensagem)
    else if (tipo == 'error') this.mostrarErroEmitter.emit(mensagem)
  }

}
