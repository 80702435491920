import { ReportService } from './../providers/report.service';
import { read, writeFile, XLSX$Utils } from 'xlsx';



import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';


import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/shared/loading/loading.service';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  public readonly EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  public readonly EXCEL_EXTENSION = '.xlsx';

  public listarPaises: any
  public listarStatus: any
  public listarDepartamento: any
  public listarUsuario: any
  public liberarRelatorio: any = false

  public dataInicial: any
  public dataFinal: any

  public model: boolean
  public relatorioCliente: boolean
  public relatorioContato: boolean
  public relatorioVendas: boolean
  public baixarRelatorioo: boolean
  public botoes: boolean
  public usuarioLogado = window.localStorage.getItem('usuario')



  constructor(
    private reportService: ReportService,
    private router: Router,
    private loadingService: LoadingService,

  ) { }

  async ngOnInit() {
    try {
      this.liberarRelatorio = await this.reportService.infoUsuario(this.usuarioLogado)

      if(this.liberarRelatorio.departamento_dep_id == 6){
        this.liberarRelatorio = true
      }else{
        this.liberarRelatorio = false

      }

      this.listarPaises = await this.reportService.ListarPaises()
      this.listarStatus = await this.reportService.ListarStatus()
      this.listarDepartamento = await this.reportService.ListagensDepartamentos()

      this.reportService.checkToken(this.usuarioLogado)
      this.botoes = false


    } catch (error) {
      console.log('erro')
    }

  }

  async formularioContato() {
    this.relatorioContato = true
    this.relatorioCliente = false
    this.relatorioVendas = false
    this.model = true
    this.botoes = true
  }

  async formularioCliente() {
    this.relatorioCliente = true
    this.relatorioContato = false
    this.relatorioVendas = false
    this.model = true
    this.botoes = true
  }

  async formularioVendas() {
    this.relatorioCliente = false
    this.relatorioContato = false
    this.relatorioVendas = true
    this.model = true
    this.botoes = true
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'dados1': worksheet }, SheetNames: ['dados1'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + new Date().getTime() + this.EXCEL_EXTENSION);
  }



  async RelatorioCliente() {
    this.loadingService.loadingState(true, "Gerando Relatorio de Cliente...")

    let pais = (<HTMLInputElement>document.getElementById('pais')).value
    let status = (<HTMLInputElement>document.getElementById('status')).value

    let relatorio = await this.reportService.relatorioCliente(pais, status)

    this.exportAsExcelFile(relatorio[0], 'relatorio_clientes')
    this.loadingService.loadingState(false)


  }



  async RelatorioAndamento() {
    this.loadingService.loadingState(true, "Gerando Relatorio de Atendimento...")
    let status = (<HTMLInputElement>document.getElementById('statusAtendimento')).value
    let Departamento = (<HTMLInputElement>document.getElementById('departamento')).value
    let finalizado = (<HTMLInputElement>document.getElementById('finalizado')).value


    let relatorio = await this.reportService.relatorioAtendimento(status, Departamento, finalizado)

    this.exportAsExcelFile(relatorio[0], 'relatorio_atendimento')
    this.loadingService.loadingState(false)


  }

  async RelatorioVendas() {
    this.loadingService.loadingState(true, "Gerando Relatorio de Vendas...")

    // if (this.dataInicial && !this.dataFinal || !this.dataInicial && this.dataFinal) return "Data informada incorreta"
    
    let relatorio = await this.reportService.relatorioVendas(this.usuarioLogado, this.dataInicial, this.dataFinal)

    this.loadingService.loadingState(false)

    if (relatorio[0].length == 0) return

    this.exportAsExcelFile(relatorio[0], 'relatorio_vendas')
  }


  voltar() {
    this.relatorioContato = false
    this.relatorioCliente = false
    this.relatorioVendas = false
    this.model = false
    this.botoes = true
  }


}
