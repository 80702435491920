import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class QueryService {
  public resultCnpj
  public resultCnpjExterno
  public resultCep
  public newCNPJ
  constructor(private http:HttpClient) { }

  // public consultaCpfCnpj(consulta: { numeroDeSerie: string; login: string; senha: string; }): Observable<any>{  
  //   return this.http.post<any>(`${environment.api}/clients/query` , consulta, { observe: 'response' })
  
  async checkToken(usuario: string){
    const checkToken = await this.http.post<any>(`${environment.api}/checkToken`, {usuario}).toPromise()
    
  }
    
    
  // }
  async consultaCpfCnpj(consulta:any){
    this.resultCnpj = await this.http.post<any>(`${environment.api}/clients/query`, consulta).toPromise()
    return this.resultCnpj
  }



  async ajusteCNPJ_RPA(novoCNPJ:any){
    this.newCNPJ = await this.http.post<any>(`${environment.api}/consulta/solicitacaoRPA`, novoCNPJ).toPromise()
    return JSON.stringify(this.newCNPJ)
  }


}
