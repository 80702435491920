import { Router } from '@angular/router';
import { routes } from 'src/app/app-routing.module';
import { LoginService } from './../../pages/login/providers/login.service';
import { LoadingService } from './../loading/loading.service'
import { EventEmitter, Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs"
import { catchError, finalize, map } from "rxjs/operators"
import { ErrosService } from '../errors/erros.service';
import { ErrorsComponent } from '../errors/errors.component';
import { AuthInterceptorService } from './auth-interceptor.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private loginService: LoginService,
        private router: Router,
        private errosService: ErrosService,
        private errorsComponent: ErrorsComponent,
        private loadingService: LoadingService,
        private authService: AuthInterceptorService
    ) { }

    public mostrarErrorEmitter = new EventEmitter<any>()

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const token = this.loginService.getAuthToken()
        let request: HttpRequest<any> = req
        
        if (token && !this.loginService.isTokenExpired(token)) {
            request = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${token}`)
            })
        }

        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {
                let erroMsg = ''

                if (err.error instanceof ErrorEvent) {
                    erroMsg = err.error.message
                } else {
                    if(err.error.errorMsg) this.authService.abrirMensagem(err.error.errorMsg, 'error')
                    erroMsg = err.error
                }


                this.errorsComponent.dialogError(erroMsg)
                this.loadingService.loadingState(false)

                return throwError(() => new Error(erroMsg))
            }),
            
            map(resp => {
                let resposta:any


                if (resp instanceof HttpResponse) {   
                    resposta = resp.clone();
            
                    if(resposta.body.successMsg) this.authService.abrirMensagem(resposta.body.successMsg, 'success')
                }


                return resp
            })

        )

    }



    public HandleError(err: HttpErrorResponse) {
        if (err.error instanceof ErrorEvent) {
            let erroMsg = ''
            // console.log("ocorreu um erro: ", err)

        } else {
            if (err.error.msg == "token invalido") {
                localStorage.clear();
                sessionStorage.clear()
                location.reload()
            }
        }


        return throwError(err.error)
    }

}


