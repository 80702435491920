import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginService } from '../login/providers/login.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  public nav = false
  public letras = true
  public user: boolean
  public isHandset$: boolean

  public mostrarMenu: boolean


  constructor(
    private loginService: LoginService
  ) {
  }


  async ngOnInit() {
    const verificacaoAdmin = await this.loginService.isAdmin()

    if (verificacaoAdmin) this.user = true
    else this.user = false
  }

  async fechar() {
    if (!this.nav) this.nav = true
    else this.nav = false
  }

  
  async logoff() {
    localStorage.clear();
    sessionStorage.clear()
    window.location.reload()
  }

}
