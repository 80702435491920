import { LoginService } from '../../pages/login/providers/login.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router:Router,
    private loginService: LoginService
    ){}

  canActivate(
    next: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot):boolean{
      if (this.loginService.isUserLoggedin()) {
        return true
      }else{
        this.router.navigate(['login'])
        return false
      }
    }
  
}
