import { Component, EventEmitter, Injectable, OnInit } from '@angular/core';

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class ErrorsComponent implements OnInit {
  error: any;

  constructor(

  ) { }

  ngOnInit() {
  }

  dialogError(err: any){
    // this.mostrarErrorEmitter.emit(true)

    // this.mostrarMensagem(err.errorMsg)

    return this.error
  }
}
