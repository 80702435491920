import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  public loading:boolean
  public ativar:any
  
  
  constructor() { }
  
  ngOnInit() {
    
  }


    
  numero: number = 99


  teste(){
  }

}
