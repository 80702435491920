import { UsersService } from './../../providers/users.service';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { MyErrorStateMatcher } from '../create-users/create-users.component';
import { LoadingService } from 'src/app/shared/loading/loading.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  public VerStorage = (name) => {
    return window.localStorage.getItem(name)
  }

  public InserirStorage = (nameArm, valorArm: number | string) => {
    window.localStorage.setItem(nameArm, String(valorArm))
  }
  @Output() loadingEvent = new EventEmitter<any>()

  @ViewChild("id") id: ElementRef
  @ViewChild("username") username: ElementRef
  @ViewChild("password") password: ElementRef
  @ViewChild("is_superuser") is_superuser: ElementRef
  @ViewChild("first_name") first_name: ElementRef
  @ViewChild("last_name") last_name: ElementRef
  @ViewChild("cpf") cpf: ElementRef
  @ViewChild("email") email: ElementRef
  @ViewChild("is_staff") is_staff: ElementRef
  @ViewChild("is_active") is_active: ElementRef
  @ViewChild("departamento_dep_id") departamento_dep_id: ElementRef
  public usuarioLogado = window.localStorage.getItem('usuario')
  public idUser
  public usuario = {
    id: '',
    username: '',
    password: '',
    is_superuser: '',
    first_name: '',
    last_name: '',
    cpf: '',
    email: '',
    is_active: '',
    departamento_dep_id: ''
  }




  public loading: any
  public lista: any
  public interpolacaoCliente: any
  public cep: ''
  public userEdite: any
  public userDep: any


  public paginacao = {
    page: '0',
    size: '10'
  }

  public consulta = {
    tipo: 'Usuario',
    conteudo: '',
    page: 0,
    size: 10
  }

  public cnpj = {
    cnpj: ''
  }

  public model: boolean;
  public admin: boolean;
  public ativo: boolean;
  public inativo: boolean

  public ativar: any


  matcher = new MyErrorStateMatcher();

  userFormControl = new FormControl('', [Validators.required]);
  emailFormControl = new FormControl('', [Validators.required]);
  nomeFormControl = new FormControl('', [Validators.required]);
  senhaFormControl = new FormControl('', [Validators.required]);
  sobrenomeFormControl = new FormControl('', [Validators.required]);
  cpfFormControl = new FormControl('', [Validators.required]);



  constructor(
    private usersService: UsersService,
    private router: Router,
    private loadingService: LoadingService
  ) { }


  async ngOnInit() {
    this.loadingService.loadingState(true, "Buscando Usuarios...")

    this.usersService.checkToken(this.usuarioLogado)

    try {
      this.lista = await this.usersService.listarUsuarios(this.paginacao)


    } catch (error) {
      console.log('ERRO NA LISTAGEM DE USUÁRIOS')
    }
    this.loadingService.loadingState(false)

  }

  async pagina(origem: string) {
    let valor: number

    if (origem == 'doubletras') {
      valor = Number(this.VerStorage('page')) - 20
      this.InserirStorage('page', valor)
    }

    if (origem == 'tras' && Number(this.VerStorage('page')) > 0) {
      valor = Number(this.VerStorage('page')) - 10
      this.InserirStorage('page', valor)

    }

    if (origem == 'frente') {
      valor = Number(this.VerStorage('page')) + 10
      this.InserirStorage('page', valor)

    }

    if (origem == 'doublefrente') {
      valor = Number(this.VerStorage('page')) + 30
      this.InserirStorage('page', valor)
    }

    this.VerStorage('page')
    if (Number(this.VerStorage('page')) <= 0) {
      this.InserirStorage('page', 0)
    }
    this.paginacao.page = this.VerStorage('page')
    let verificarMax = await this.usersService.listarUsuarios(this.paginacao)

    if (verificarMax.length > 0) {
      this.lista = verificarMax
    }


  }


  async teste() {
    setTimeout(() => {
      let select = (<HTMLInputElement>document.querySelector('input[id="ii"]')).value
    }, 300)


  }


  async filtrarUsuario() {
    this.loadingService.loadingState(true, "Filtrando Usuarios...")

    this.lista = await this.usersService.filtroUsuarios(this.consulta)
    this.loadingService.loadingState(false)

    return this.lista
  }

  async editar(id: any, cpf: any) {
    this.loadingService.loadingState(true, "Buscando Usuario...")

    this.ativo = false
    this.admin = false

    try {
      let editar = await this.usersService.acharUsuario(id, cpf)

      this.userEdite = editar[0]

      this.userDep = editar[1]

      this.idUser = id
      this.model = true

      console.log(`USUARIO ATIVO ${this.userEdite.is_active}`)
      console.log(this.userEdite)      
      console.log(editar)      

      if (this.userEdite.is_superuser) this.admin = true

      if (this.userEdite.is_active) this.ativo = true

    } catch (error) {
      console.log('ERRO')
    }

    this.loadingService.loadingState(false)

  }


  async salvar() {
    this.loadingService.loadingState(true, "Salvando Alterações...")

    try{
      this.usuario = {
        id: this.idUser,
        username: this.username.nativeElement.value,
        password: this.password.nativeElement.value,
        is_superuser: (<HTMLInputElement>document.querySelector('input[name="usuario.is_superuser"]:checked')).value,
        first_name: this.first_name.nativeElement.value,
        last_name: this.last_name.nativeElement.value,
        cpf: this.cpf.nativeElement.value,
        email: this.email.nativeElement.value,
        is_active: (<HTMLInputElement>document.querySelector('input[name="usuario.is_active"]:checked')).value,
        departamento_dep_id: (<HTMLInputElement>document.querySelector('input[id="idSel"]')).value
      }
      if (this.usuario.departamento_dep_id == "") {
        this.usuario.departamento_dep_id = this.userEdite.departamento_dep_id
  
      }

      const inserir = await this.usersService.alterarUsuario(this.usuarioLogado, this.usuario)
      window.location.reload()
    }catch(e){
      console.log('ERRO')
    }
    

    this.loadingService.loadingState(false)

  }

  cancelar() {
    this.model = false
  }
}