import { AttendanceComponent } from './../components/attendance/attendance.component';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  constructor(private http: HttpClient) { }

  async listarContato(paginacao) {
    const lista = await this.http.post<any>(`${environment.api}/contatoLista`, {paginacao}).toPromise()
    return lista
  }

  async notificacao(usuario: { usuario: string; }) {
    const notificacao = await this.http.post<any>(`${environment.api}/notificacao`, usuario).toPromise()
    return notificacao
  }

  async listagens(usuario: { usuario: string; }) {
    const lista = await this.http.post<any>(`${environment.api}/listagens`, usuario).toPromise()
    return lista
  }

  async vendasCliente(idCliente: {id: any}) {
    const vendas = await this.http.post<any>(`${environment.api}/vendasCliente`, idCliente).toPromise()
    return vendas
  }

  async infoProduto(idProduto: {id: any}) {
    const produto = await this.http.post<any>(`${environment.api}/retorntaInfoProduto`, idProduto).toPromise()
    return produto
  }


  async filtraCliente(filtro: Object) {
    const lista = await this.http.post<any>(`${environment.api}/filtrarAtendimentos`, filtro).toPromise()
    return lista
  }

  async informacaoUsuario(usuario: string) {
    const user = await this.http.post<any>(`${environment.api}/infoUsuario`, {usuario}).toPromise()
    return user

  }


  async novoContato(atendimento: any, usuario: string) {
    const novoContato = await this.http.post<any>(`${environment.api}/novoContato`, { atendimento, usuario }).toPromise()
  }

  async abrirContato(id: string, usuario: string) {
    const atendimento = await this.http.post<any>(`${environment.api}/retornaAtendimento`, {id, usuario}).toPromise()
    return atendimento
  }



  async listarSelect(){
    const status = await this.http.post<any>(`${environment.api}/listarSelect`, {}).toPromise()
    return status
  } 

  async novoAtendimento(Attendance:any){
    const novoAtendimento = await this.http.post<any>(`${environment.api}/novoAtendimento`, Attendance).toPromise()
    return novoAtendimento
    
  }
}
