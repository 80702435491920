import { Component, OnInit } from '@angular/core';
import { ErrorsComponent } from 'src/app/shared/errors/errors.component';
import { AuthInterceptorService } from 'src/app/shared/http-interceptors/auth-interceptor.service'

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

  private tempo:number = 10000

  public mostrarMensagem: boolean = false
  public mensagem: string = 'ERRO QUALQUER...'
  public classeMsg: string
  public timeOut: any

  constructor(
    private errorsComponent:ErrorsComponent,
    private interceptorService:AuthInterceptorService
  ) { }

  ngOnInit() {
    this.interceptorService.mostrarErroEmitter.subscribe(
      (conteudo: any) => {
        this.abrirMensagem(conteudo, 'errorMsg')
      }
    )  

    this.interceptorService.mostrarSucessoEmitter.subscribe(
      (conteudo: any) => {
        this.abrirMensagem(conteudo, 'successMsg')
      }
    )  

  }

  
  abrirMensagem(mensagem:any, classe:string){
    clearTimeout(this.timeOut)
    
    if(mensagem.length == 0 || typeof mensagem != 'string') this.mensagem = 'ERRO DESCONHECIDO...'
    else this.mensagem = mensagem

    this.mostrarMensagem = true
    this.classeMsg = classe


    this.timeOut = setTimeout( () => this.fecharMensagem(), this.tempo )
  }


  fecharMensagem(){
    clearTimeout(this.timeOut)
    this.mostrarMensagem = false
  }

}