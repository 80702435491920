import { AttendanceService } from './../../providers/attendance.service';
import { LoadingService } from '../../../../shared/loading/loading.service'
import { Component, ElementRef, Injectable, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';


@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})

@Injectable({
  providedIn: 'root'
})

export class AttendanceComponent implements OnInit,OnDestroy {

  public VerStorage = (name) => {
    return window.localStorage.getItem(name)
  }

  public InserirStorage = (nameArm, valorArm: number | string) => {
    window.localStorage.setItem(nameArm, String(valorArm))
  }

  public Attendance: any;
  public SelectOptions: any;
  public AttendanceStatus: any;
  public AttendanceEquipamento: any
  public AttendanceAcao: any
  public AttendanceMotivoContato: any
  public AttendanceDepartamento: any
  public AttendanceAmbiente: any
  public AttendanceOrigem: any
  public AttendanceUser: any
  public AttendanceSoftware: any
  public AttendanceUserList: any


  public venda: boolean
  public notaFiscal: boolean
  public orcamento: boolean
  public agendamento: boolean;
  public redirecionamento: boolean = true;
  public atribuicao: boolean = false;
  public produtoCliente: boolean = false
  public ambiente: any;
  public equipamento: any;

  public selectEquipamento: any
  public selectSoftware: any

  public labelProduto: string = 'Produto'

  public sem_equip_amb: boolean = false

  public loading: any

  public base64PDF: any
  public listaContatos: any
  public limitarSelect: any

  public placeholdInput = 'Arraste o PDF...'

  public cnpjCliente: string
  public possuiCPF: boolean
  public exigeCPF: boolean

  public statAtendimento: any

  public rangeData = {
    dataInicial: '',
    dataFinal: ''
  }


  public info: {
    idCliente: '',
    idStatus: '',
    idDepto: ''
  }


  public atendimento = {
    nSerie: '',
    departamento: '',
    origemContato: '',
    equipamento: '',
    ambiente: '',
    acao: '',
    statusAtendimento: '',
    motivoContato: '',
    descricao: '',
    contatoAtenID: '',
    cnpjCliente: '',
    usuario: '',
    agendamento: '',
    representante: '',
    notaFiscal: '',
    orcamento: '',
    software: '',
    produtoCliente: ''
  }


  public consulta = {
    tipo: 'Razao Social',
    conteudo:<any> '',
    page: '0',
    size: '10'
  }


  public infoUsuario: any;
  public bloqueioForm: boolean
  public bloqueioDepartamento: boolean

  public consultaDepartamento: boolean
  public consultaDtAgendamento: boolean
  public checkUpdate: Number | any
  public listaAtendimento
  public model = false
  liberaFormEquip: boolean;
  liberaFormSoft: boolean;
  sem_software: boolean;
  AttendanceVendas: any;


  constructor(
    private attendanceService: AttendanceService,
    private router: Router,
    private loadingService: LoadingService,

  ) { }
 

  async ngOnInit() {
    this.loadingService.loadingState(true, "Buscando Atendimentos...")
    this.InserirStorage('page', this.consulta.page)
    this.listaContatos = await this.attendanceService.listarContato(this.consulta)
    this.checkUpdate = setInterval(async()=>{
      this.listaContatos = await this.attendanceService.listarContato(this.consulta)
      this.listaContatos = this.listaContatos.sort((a: { id: number; }, b: { id: number; }) => {
        if (a.id > b.id) {
          return -1
        } else {
          return true
        }
      })
    },5000)

    console.log(this.checkUpdate)
      
    this.SelectOptions = await this.attendanceService.listarSelect()

    this.AttendanceStatus = this.SelectOptions[0]
    this.AttendanceEquipamento = this.SelectOptions[1]
    this.AttendanceAcao = this.SelectOptions[2]
    this.AttendanceMotivoContato = this.SelectOptions[3]
    this.AttendanceDepartamento = this.SelectOptions[4]
    this.AttendanceAmbiente = this.SelectOptions[5]
    this.AttendanceOrigem = this.SelectOptions[6]
    this.AttendanceUserList = this.SelectOptions[7]
    this.AttendanceSoftware = this.SelectOptions[8]

    this.selectEquipamento = this.AttendanceEquipamento
    this.selectSoftware = this.AttendanceSoftware
    

    let informacoesUsuario = await this.attendanceService.informacaoUsuario(this.VerStorage('usuario'))


    this.limitarSelect = informacoesUsuario.departamento_dep_id

    this.infoUsuario = informacoesUsuario


    let id_aten = this.VerStorage('id_aten')
    if (id_aten) { this.editar(id_aten) }

    this.loadingService.loadingState(false)
  }

  ngOnDestroy(): void {
    clearInterval(this.checkUpdate)
  }

  SelectedFile(event) {
    let reader = new FileReader()
    let file = event.target.files[0]
    if (file.type != "application/pdf") return "Formato invalido"
    this.placeholdInput = file.name
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      this.base64PDF = reader.result
    }
  }

  DownloadAnexo(arquivo) {
    let nameFile = arquivo + '.pdf'
    let a = document.createElement("a")
    if (arquivo == 'Orçamento') {
      a.href = this.listaAtendimento[0].orcamento
    } else {
      a.href = this.listaAtendimento[0].nota_fiscal
    }
    a.download = nameFile
    a.click()
  }

  redirecionar() {
    let idAtendimento = window.localStorage.getItem('idAtd')

    if (idAtendimento) {
      this.editar(idAtendimento)
    }
  }



  async pagina(origem?: string) {
    let verificarMax: string | any[]
    let valor: number
    let page: string = this.VerStorage('page')

    if (origem == 'doubletras') {
      valor = Number(this.VerStorage('page')) - 20
      this.InserirStorage('page', valor)
    }

    if (origem == 'tras' && Number(this.VerStorage('page')) > 0) {
      valor = Number(this.VerStorage('page')) - 10
      this.InserirStorage('page', valor)

    }

    if (origem == 'frente') {
      valor = Number(this.VerStorage('page')) + 10
      this.InserirStorage('page', valor)

    }

    if (origem == 'doublefrente') {
      valor = Number(this.VerStorage('page')) + 30
      this.InserirStorage('page', valor)
    }
 
    this.VerStorage('page')
    if (Number(this.VerStorage('page')) <= 0) {
      this.InserirStorage('page', 0)
    }
    this.consulta.page = this.VerStorage('page')
    if (this.consulta.conteudo == '') {
      verificarMax = await this.attendanceService.listarContato(this.consulta)

    } else {
      verificarMax = await this.attendanceService.filtraCliente(this.consulta)
    }
    console.log(verificarMax)
    if (verificarMax.length > 0) {
      this.listaContatos = verificarMax

      this.listaContatos = this.listaContatos.sort((a: { id: number; }, b: { id: number; }) => {
        if (a.id > b.id) {
          return -1
        } else {
          return true
        }
      })
    }


  }


  async filtrar() {
    clearInterval(this.checkUpdate)
    if(this.consulta.tipo == 'Data de Agendamento') this.consulta.conteudo = this.rangeData

    if (this.consulta.conteudo == '') return this.ngOnInit()
    this.loadingService.loadingState(true, "Filtrando...")
    const filtrar = await this.attendanceService.filtraCliente(this.consulta)


    if (filtrar.length > 0) {
      this.listaContatos = filtrar
      this.listaContatos = this.listaContatos.sort((a: { id: number; }, b: { id: number; }) => {
        if (a.id > b.id) {
          return -1
        } else {
          return true
        }
      })
    }

    this.loadingService.loadingState(false)
  }



  async editar(id) {
    this.sem_equip_amb = false
    this.sem_software = false
    this.liberaFormEquip = false
    this.liberaFormSoft = false

    this.loadingService.loadingState(true,"Buscando Atendimento...")


    localStorage.removeItem('id_aten')

    this.AttendanceSoftware = this.SelectOptions[8]

    
    try {
      this.InserirStorage('idAten', id)
      let usuario = this.VerStorage('usuario')
      
      this.exigeCPF = false
      
      this.listaAtendimento = await this.attendanceService.abrirContato(id, usuario)
      this.AttendanceVendas = this.listaAtendimento[0].vendas


      this.listaAtendimento[1] = await this.listaAtendimento[1].sort((a: { id: number; }, b: { id: number; }) => {
        if (a.id > b.id) {
          return -1
        } else {
          return true
        }
      })
      this.atendimento.contatoAtenID = this.listaAtendimento[0].id
      this.listaAtendimento[0].cliente_aten_id[1] ? this.atendimento.cnpjCliente = this.listaAtendimento[0].cliente_aten_id[1] : this.atendimento.cnpjCliente = ''


      if (this.atendimento.cnpjCliente) this.possuiCPF = true
      else this.possuiCPF = false

      if(this.listaAtendimento[0].ambiente_con_id && !this.listaAtendimento[0].software_con_id){
        this.sem_software = true
        let ambiente = this.AttendanceAmbiente.filter(amb => {
          return amb.ambiente_amb == this.listaAtendimento[0].ambiente_con_id
        })
        ambiente = ambiente[0].id

        let selectSoft = this.AttendanceSoftware.filter(soft => {
          return (soft.ambiente_id == ambiente || soft.ambiente_id == null)
        })

        this.AttendanceSoftware = selectSoft
      }

      let deptoId = this.SelectOptions[4].filter((depto) => {
        return depto.departamento_dep == this.listaAtendimento[0].departamento_aten_id
      })

      deptoId = deptoId[0].id

      if(!this.listaAtendimento[0].equipamento_con_id || !this.listaAtendimento[0].ambiente_con_id) this.sem_equip_amb = true

      if (!this.listaAtendimento[0].finalizado_con) {
        if ( deptoId == this.listaAtendimento[2].id
          || deptoId == 6 && this.infoUsuario.departamento_dep_id == 2 || this.infoUsuario.is_superuser) {
          this.bloqueioDepartamento = false
          this.bloqueioForm = false

        } else {
          this.bloqueioDepartamento =  true
          this.bloqueioForm = false
        }


      } else {
        this.bloqueioDepartamento = false
        this.bloqueioForm = true

      }

      this.model = true


    } catch (error) {
      // console.log(error)
    }
    this.loadingService.loadingState(false)
  }

  filterEquipamento(ambiente:string) {
    this.liberaFormEquip = false
    this.liberaFormSoft = false


    if(ambiente) this.liberaFormEquip = true

    this.atendimento.equipamento = ''
    this.atendimento.software = ''

    if(ambiente == '8') this.labelProduto = 'Curso'
    else this.labelProduto = 'Equipamento'


    let selectEquip = this.selectEquipamento.filter((amb) => {
      return amb.ambiente_id == ambiente
    })

    let selectSoft = this.selectSoftware.filter((amb) => {
      return (amb.ambiente_id == ambiente || amb.ambiente_id == null)
    })


    this.AttendanceEquipamento = selectEquip
    this.AttendanceSoftware = selectSoft
  }

  selecionarEquipamento(equip: any){
    if(equip) this.liberaFormSoft = true
    else this.liberaFormSoft = false

    this.atendimento.software = ''
  }

  selectDepartamento() {
    this.consultaDepartamento = false
    this.consultaDtAgendamento = false


    if (this.consulta.tipo == 'Departamento') this.consultaDepartamento = true
    if (this.consulta.tipo == 'Data de Agendamento') this.consultaDtAgendamento = true
  }


  statusAtendimento(statAtd: string) {
    this.venda = false
    this.notaFiscal = false
    this.orcamento = false
    this.exigeCPF = false
    this.redirecionamento = true
    this.atribuicao = false
    this.sem_software = false

    if(!this.listaAtendimento[0].equipamento_con_id || !this.listaAtendimento[0].ambiente_con_id) this.sem_equip_amb = true
    else this.sem_equip_amb = false

    switch (statAtd) {
      case '2':
      case '3':
      case '4':
      case '8':
        this.exigeCPF = true
        break

      case '9':
        this.exigeCPF = true
        this.orcamento = true
        break;

      case '12':
        this.atribuicao = true
        this.redirecionamento = false
        break

      case '16':
        this.notaFiscal = true
        break;

      case '18':
        this.venda = true
        break;

      case '22':
        this.orcamento = true
        break;

      case '38':
        this.sem_equip_amb = true
        break

      case '40':
        this.sem_software = true
        break
  
    }
  }



  acaoAtendimento(id) {
    this.agendamento = false
    this.atribuicao = false
    this.produtoCliente = false

    switch (id) {
      case '5':
        this.agendamento = true
        break;

      case '11':
        this.produtoCliente = true
        break;
  
    }
  }


  async inserirAtendimento() {
    this.loadingService.loadingState(true, "Salvando Atendimento...")

    try {
      const user = this.VerStorage('usuario')
      this.atendimento.usuario = user

      if (this.notaFiscal) {
        this.atendimento.notaFiscal = this.base64PDF
      } else {
        this.atendimento.notaFiscal = ''

      }
      if (this.orcamento) {
        this.atendimento.orcamento = this.base64PDF
      } else {
        this.atendimento.orcamento = ''

      }

      if(this.cnpjCliente) this.atendimento.cnpjCliente = this.cnpjCliente


      await this.attendanceService.novoAtendimento(this.atendimento).then(() => {
        this.editar(this.VerStorage('idAten'))
      })
      this.atendimento = {
        nSerie: '',
        departamento: '',
        origemContato: '',
        equipamento: '',
        ambiente: '',
        acao: '',
        statusAtendimento: '',
        motivoContato: '',
        descricao: '',
        contatoAtenID: '',
        cnpjCliente: '',
        usuario: '',
        agendamento: '',
        representante: '',
        notaFiscal: '',
        orcamento: '',
        software: '',
        produtoCliente: ''
      }

    } catch (error) {
      // console.log(error)
    }
    this.loadingService.loadingState(false)
  }

  voltar() {
    this.model = false

    this.atendimento = {
      nSerie: '',
      departamento: '',
      origemContato: '',
      equipamento: '',
      ambiente: '',
      acao: '',
      statusAtendimento: '',
      motivoContato: '',
      descricao: '',
      contatoAtenID: '',
      cnpjCliente: '',
      usuario: '',
      agendamento: '',
      representante: '',
      notaFiscal: '',
      orcamento: '',
      software: '',
      produtoCliente: ''
    }
  }

}
