import { viewClassName } from '@angular/compiler';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { viewAttached } from '@angular/core/src/render3/instructions';
import { FlashMessagesService } from 'flash-messages-angular/module/flash-messages.service';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import { ProfileService } from '../providers/profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  attUsuario = {
    nome: '',
    sobrenome: '',
    cpf: '',
    departamento: '',
    username: '',
    email: '',
    senha: '',
    confirmarSenha: ''
  }
  atualizacao;
  perfil

  constructor(
    private profileService: ProfileService,
    private _flashMessagesService: FlashMessagesService,
    private loadingService: LoadingService
  ) { }

  async ngOnInit() {
    this.loadingService.loadingState(true, "Buscando Perfil..")

    const usuarioLogado = window.localStorage.getItem('usuario')
    await this.profileService.checkToken(usuarioLogado)
    this.perfil = await this.profileService.infoUsuario(usuarioLogado)


    this.attUsuario = {
      nome: this.perfil.first_name,
      sobrenome: this.perfil.last_name,
      cpf: this.perfil.cpf,
      departamento: this.perfil.departamento_dep_id,
      username: this.perfil.username,
      email: this.perfil.email,
      senha: '',
      confirmarSenha: ''
    }

    this.loadingService.loadingState(false)

  }

  async onSubmit() {
    this.loadingService.loadingState(true, "Salvando Perfil...")
      try {
        const resposta = await this.profileService.ajusteUsuario({senha: this.attUsuario.senha, confirmarSenha: this.attUsuario.confirmarSenha})
        this.attUsuario.senha = ''
        this.attUsuario.confirmarSenha = ''

      } catch (e) {
        console.log('ERRO AO SALVAR PERFIL')
      }
    

    this.loadingService.loadingState(false)

  }

}
