import { AttendanceService } from './../../providers/attendance.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/shared/loading/loading.service';


@Component({
  selector: 'app-add-attendance',
  templateUrl: './add-attendance.component.html',
  styleUrls: ['./add-attendance.component.css']
})
export class AddAttendanceComponent implements OnInit {
  public usuario = {
    usuario: window.localStorage.getItem('usuario')
  }
  public lista: { msg: any[]; }
  public clienteRed = []
  public dataFormatada: any
  public orcamento: boolean = false
  public produtosCliente: any
  public infoProdutoCliente: any;

  public valorAmbiente: string
  public valorEquipamento: string
  public valorSoftware: string

  public newAttendance = {
    dataAtual: new Date(),
    atendente: '',
    departamento: '',
    origem: '',
    cliente: '',
    motivo_contato: '',
    status_atendimento: '',
    acao: '',
    direcionar_departamento: '',
    descricao: '',
    equipamento: '',
    ambiente: '',
    orcamento: '',
    software: '',
    produtoCliente: ''
  }

  public selectEquipamento: any
  public selectSoftware: any
  public labelProduto: string = 'Produto'

  public liberaFormEquip: boolean
  public liberaFormSoft: boolean

  public placeholdInput: any = 'Arraste o PDF...'
  public base64PDF: any = ''
  produtoNserie: boolean;
  blockAmbiente: boolean;
  public retornoBack: boolean;


  constructor(
    private attendanceService: AttendanceService,
    private router: Router,
    private loadingService: LoadingService,
  ) { }

  async ngOnInit() {
    try {
      this.loadingService.loadingState(true,"Carregando informações...")

      let id_cli = localStorage.getItem('id_cli')
      this.dataFormatada = this.newAttendance.dataAtual
        .toLocaleString()
        .split(' ')[0]
      this.lista = await this.attendanceService.listagens(this.usuario)
      if (id_cli) {
        this.CriarAtenRed(id_cli)
      }

      this.selectEquipamento = this.lista.msg[8]
      this.selectSoftware = this.lista.msg[10]

    } catch {

    }

    this.loadingService.loadingState(false)

  }

  async vendasCliente(id:any){
    this.newAttendance.produtoCliente = null

    this.produtosCliente = await this.attendanceService.vendasCliente({id:id})
    this.produtosCliente = this.produtosCliente.msg
  }

  async infoProduto(id:any){
    this.newAttendance.ambiente = ''
    this.newAttendance.equipamento = ''
    this.newAttendance.software = ''

    this.valorAmbiente = ''
    this.valorEquipamento = ''
    this.valorSoftware = ''

    this.infoProdutoCliente = await this.attendanceService.infoProduto({id:id})
    this.infoProdutoCliente = this.infoProdutoCliente.msg

    this.newAttendance.ambiente = this.infoProdutoCliente.ambiente.ambiente_amb
    this.newAttendance.equipamento = this.infoProdutoCliente.equipamento.equipamento_equi
    this.newAttendance.software = this.infoProdutoCliente.software.software_soft
    this.newAttendance.produtoCliente = id
  }

  acaoAtendimento(id) {
    this.blockAmbiente = false
    this.retornoBack = false

    this.produtoNserie = false
    this.newAttendance.produtoCliente = null

    switch (id) {
      case '11':
        this.produtoNserie = true
        this.retornoBack = true

        this.blockAmbiente = true
        this.liberaFormEquip = false
        this.liberaFormSoft = false

        this.newAttendance.ambiente = ''
        this.newAttendance.equipamento = ''
        this.newAttendance.software = ''
        break;
  
    }
  }


  filterEquipamento(ambiente:string) {
    this.liberaFormEquip = false
    this.liberaFormSoft = false

    if(ambiente) this.liberaFormEquip = true

    this.newAttendance.equipamento = ''
    this.newAttendance.software = ''

    if(ambiente == '8') this.labelProduto = 'Curso'
    else this.labelProduto = 'Equipamento'


    let selectEquip = this.selectEquipamento.filter((amb) => {
      return amb.ambiente_id == ambiente
    })

    let selectSoft = this.selectSoftware.filter((amb) => {
      return (amb.ambiente_id == ambiente || amb.ambiente_id == null)
    })


    this.lista.msg[8] = selectEquip
    this.lista.msg[10] = selectSoft
  }

  selecionarEquipamento(equip: any){
    if(equip) this.liberaFormSoft = true
    else this.liberaFormSoft = false

    this.newAttendance.software = ''
  }


  async CriarAtenRed(id:any) {
    localStorage.removeItem('id_cli')
    this.clienteRed = this.lista.msg[2].filter(obj => obj.id == id)
  }

  StatusAtendimento(id: number) {
    if (id == 9 || id == 22) {
      this.orcamento = true
    } else {
      this.orcamento = false
    }
  }

  SelectedFile(event) {
    let reader = new FileReader()
    let file = event.target.files[0]
    if (file.type != "application/pdf") return "Formato invalido"
    this.placeholdInput = file.name
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      this.base64PDF = reader.result
    }
  }

  async onSubmit() {
    this.loadingService.loadingState(true,"Adicionando Atendimento...")

    this.newAttendance.dataAtual = this.dataFormatada
    this.newAttendance.atendente = this.lista.msg[1][0].username
    this.newAttendance.departamento = this.lista.msg[0][0].departamento_dep
    this.newAttendance.orcamento = this.base64PDF


    if (this.newAttendance.cliente !== '' && this.newAttendance.motivo_contato !== '' && this.newAttendance.status_atendimento !== '' && this.newAttendance.acao !== '' && this.newAttendance.origem !== '') {
      const novoAtendimento = await this.attendanceService.novoContato(this.newAttendance, this.usuario.usuario)
      this.router.navigate(['/atendimento'])
    }

    this.loadingService.loadingState(false)

  }


}


