import { ClientsService } from './../../providers/clients.service';
import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ControlContainer, FormControl, Validators } from '@angular/forms';
import { LoadingService } from 'src/app/shared/loading/loading.service';


@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})
export class ClientComponent implements OnInit {

  public VerStorage = (name) => {
    return window.localStorage.getItem(name)
  }

  public InserirStorage = (nameArm, valorArm: number | string) => {
    window.localStorage.setItem(nameArm, String(valorArm))
  }

  razaoFormControl = new FormControl('', [Validators.required]);
  emailFormControl = new FormControl('', [Validators.required, Validators.email])
  celularFormControl = new FormControl('', [Validators.required])
  cidadeFormControl = new FormControl('', [Validators.required])
  bairroFormControl = new FormControl('', [Validators.required])
  estadoFormControl = new FormControl('', [Validators.required])
  logradouroFormControl = new FormControl('', [Validators.required])


  @ViewChild(MatPaginator) paginator: MatPaginator;

  public loading: any
  public lista: any
  public interpolacaoCliente: any
  public cep: ''

  public resultCep: any

  public infoRep: any
  public infoPais: any
  public infoRede: any

  public idPais: any
  public nomePais: any;
  public idRede: any;
  public nomeRede: any;
  public idRep: any
  public nomeRep: any

  public selectRepresentante: boolean = false

  public paginacao = {
    page: '0',
    size: '10'
  }


  public consulta = {
    tipo: 'Razao Social',
    conteudo: '',
    page: 0,
    size: 10

  }


  public cnpj = {
    cnpj: ''
  }


  public cliente = {
    id: '',
    pais_cli_id: '',
    rede_social_cli_id: '',
    logradouro_cli: '',
    numero_cli: '',
    complemento_cli: '',
    cep_cli: '',
    cidade_cli: '',
    estado_cli: '',
    email_cli: '',
    telefone_cli: '',
    celular_cli: '',
    rede_social_user_cli: '',
    cnpj_cli: '',
    razao_cli: '',
    bairro_cli: '',
    ddi_cli: '',
    usuario_responsavel_id: ''
  }


  public model: boolean;

  public listagens: any;




  constructor(
    private clientsService: ClientsService,
    private router: Router,
    private loadingService: LoadingService,
  ) { }


  async ngOnInit() {
    this.loadingService.loadingState(true)

    let usuarioLogado = localStorage.getItem('usuario')
    let id_cli = localStorage.getItem('id_cli')
    this.clientsService.checkToken(usuarioLogado)
    try {
      this.lista = []
      this.lista = await this.clientsService.listarClientes(this.paginacao)

      if (id_cli) {
        this.editar(id_cli)
      }

    } catch (error) {
    }

    this.loadingService.loadingState(false)
  }


  async pagina(origem: string) {
    let valor: number

    if (origem == 'doubletras') {
      valor = Number(this.VerStorage('page')) - 20
      this.InserirStorage('page', valor)
    }

    if (origem == 'tras' && Number(this.VerStorage('page')) > 0) {
      valor = Number(this.VerStorage('page')) - 10
      this.InserirStorage('page', valor)

    }

    if (origem == 'frente') {
      valor = Number(this.VerStorage('page')) + 10
      this.InserirStorage('page', valor)

    }

    if (origem == 'doublefrente') {
      valor = Number(this.VerStorage('page')) + 30
      this.InserirStorage('page', valor)
    }

    this.VerStorage('page')
    if (Number(this.VerStorage('page')) <= 0) {
      this.InserirStorage('page', 0)
    }
    this.paginacao.page = this.VerStorage('page')
    let verificarMax = await this.clientsService.listarClientes(this.paginacao)
    if (verificarMax.length > 0) {
      this.lista = verificarMax
    }
  }


  async valoresListagem() {
    try {
      if (this.listagens.deptoUsuario == 2) this.selectRepresentante = true

      this.infoRede = await this.listagens.redes.filter(obj => obj.id == this.cliente.rede_social_cli_id)
      this.infoRede = this.infoRede[0]
      if (this.infoRede) {
        this.idRede = this.infoRede.id
        this.nomeRede = this.infoRede.rede_social_rede
      }


      this.infoPais = await this.listagens.paises.filter(obj => obj.id == this.cliente.pais_cli_id)
      this.infoPais = await this.infoPais[0]
      if (this.infoPais) {
        this.cliente.ddi_cli = await this.infoPais.ddi_pa
        this.idPais = this.infoPais.id
        this.nomePais = this.infoPais.pais_pa
      }



      this.infoRep = await this.listagens.representantes.filter(obj => obj.id == this.cliente.usuario_responsavel_id)
      this.infoRep = this.infoRep[0]
      if (this.infoRep) {
        this.idRep = this.infoRep.id
        this.nomeRep = this.infoRep.first_name + ' ' + this.infoRep.last_name
      }


    } catch (e) {
      // console.log(e)
      console.log('ERRO AO RETORNAR INFORMAÇÕES DAS LISTAS...')
    }
  }


  async editar(id: any) {
    this.loadingService.loadingState(true)

    try {
      let id_cli = localStorage.getItem('id_cli')
      if (id_cli) {
        localStorage.removeItem('id_cli')
      }
      let editar = await this.clientsService.acharCliente(id)
      this.cliente = editar[0]
      this.model = true
      this.cliente.id = id

      this.listagens = await this.clientsService.listagemCliente()
      await this.valoresListagem()


    } catch (error) {
      // console.log(error)

    }
    this.loadingService.loadingState(false)

  }


  async ApiCep() {
    try {
      if (this.cliente.cep_cli.length == 8) {
        this.loadingService.loadingState(true, "Buscando infomações de endereço..")
        let link = 'https://ws.apicep.com/cep.json?code=' + this.cliente.cep_cli

        this.resultCep = await this.clientsService.buscaCep(link)
        if (this.resultCep.status == 200) {
          this.cliente.estado_cli = this.resultCep.state
          this.cliente.cidade_cli = this.resultCep.city
          this.cliente.logradouro_cli = this.resultCep.address
          this.cliente.bairro_cli = this.resultCep.district
        } else {
          this.resultCep = ''
          link = 'https://brasilapi.com.br/api/cep/v1/' + this.cliente.cep_cli
          this.resultCep = await this.clientsService.buscaCep(link)

          if (this.resultCep.cep != '') {
            this.cliente.estado_cli = this.resultCep.state
            this.cliente.cidade_cli = this.resultCep.city
            this.cliente.logradouro_cli = this.resultCep.street
            this.cliente.bairro_cli = this.resultCep.neighborhood
          } else {
            this.resultCep = ''
            link = `https://viacep.com.br/ws/${this.cliente.cep_cli}/json/`
            this.resultCep = await this.clientsService.buscaCep(link)

            if (this.resultCep != '') {
              this.cliente.estado_cli = this.resultCep.uf
              this.cliente.cidade_cli = this.resultCep.localidade
              this.cliente.logradouro_cli = this.resultCep.logradouro
              this.cliente.bairro_cli = this.resultCep.bairro
              this.resultCep = ''
            }
          }
        }
      }

    } catch (error) {
      console.log(error, 'error:cep nao existe')
    }
    this.loadingService.loadingState(false)

  }

  async salvar() {
    this.loadingService.loadingState(true)
    await this.clientsService.alterarCliente(this.cliente)
    window.location.reload()
    this.loadingService.loadingState(false)
  }


  cancelar() {
    this.model = false
  }


  async filtrar() {
    this.loadingService.loadingState(true)

    const filtrar = await this.clientsService.filtraCliente(this.consulta)

    this.lista = filtrar


    this.lista.paginator = this.paginator
    this.loadingService.loadingState(false)

  }


  voltar() {
    window.location.reload()
  }

}
