import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrosService {
  error: any;

  constructor() { }

  dialogError(err: any){
    this.error = err
    window.location.href= '#demo-modal'

    return this.error
  }
}
