import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public usuarioLogado = window.localStorage.getItem('usuario')

  constructor(private http:HttpClient) { }

  async checkToken(usuario: string | boolean){
    const checkToken = await this.http.post<any>(`${environment.api}/checkToken`, {usuario}).toPromise()
  } 

  async listarDepartamentos () {
    const departamentos = await this.http.post<any>(`${environment.api}/listarDepartamentos`,this.usuarioLogado).toPromise()
  return departamentos
}

  async listarUsuarios(paginacao: { page: string; size: string; }){
    const lista = await this.http.post<any>(`${environment.api}/listarUsuarios`,paginacao).toPromise()
    return lista
  
  } 

  async acharUsuario(id: any,cnpj: any){
    const cliente = await this.http.post<any>(`${environment.api}/listarUsuarios/id`, {id,cnpj}).toPromise()
    return cliente
  
  }

  
  async alterarUsuario(usuarioLogado: string, usuario: { id: string; username: string; password: string; is_superuser: string; first_name: string; last_name: string; cpf: string; email: string; is_active: string; departamento_dep_id: string; }){
    const alterar = await this.http.post<any>(`${environment.api}/alterarUsuario`,{usuarioLogado,usuario}).toPromise()
    return alterar
  
  } 

  async novoUsuario(usuarioLogado: string, usuario: { username: string; password: string; is_superuser: string; first_name: string; last_name: string; cpf: string; email: string; departamento_dep_id: string; }){
    const alterar = await this.http.post<any>(`${environment.api}/novoUsuario`,{usuarioLogado,usuario}).toPromise()
    return alterar
  
  } 

  async filtroUsuarios (filtragem: object) {
    const filtro = await this.http.post<any>(`${environment.api}/filtrarUsuario`, filtragem).toPromise()
    return filtro
  }

}
