import { async } from '@angular/core/testing';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private http: HttpClient) { }

  async checkToken(usuario) {
    const checkToken = await this.http.post<any>(`${environment.api}/checkToken`, { usuario }).toPromise()
  }

  // CRUD
  async listarClientes(paginacao) {
    const lista = await this.http.post<any>(`${environment.api}/listarClientes`, paginacao).toPromise()
    return lista

  }

  async acharCliente(id: any) {
    const cliente = await this.http.post<any>(`${environment.api}/listarClientes/id`, { id }).toPromise()
    return cliente
  }


  async listagemCliente() {
    const listas = await this.http.post<any>(`${environment.api}/listagensCliente`, {}).toPromise()
    return listas
  }



  async inserirCliente(cliente: { pais_cli_id: string; rede_social_cli_id: string; logradouro_cli: string; numero_cli: string; complemento_cli: string; cep_cli: string; cidade_cli: string; estado_cli: string; email_cli: string; telefone_cli: string; celular_cli: string; rede_social_user_cli: string; cnpj_cli: string; razao_cli: string; }) {
    const inserir = await this.http.post<any>(`${environment.api}/novoCliente`, cliente).toPromise()
    return inserir

  }

  async alterarCliente(cliente: { id: string; pais_cli_id: string; rede_social_cli_id: string; logradouro_cli: string; numero_cli: string; complemento_cli: string; cep_cli: string; cidade_cli: string; estado_cli: string; email_cli: string; telefone_cli: string; celular_cli: string; rede_social_user_cli: string; cnpj_cli: string; razao_cli: string; }) {
    const alterar = await this.http.post<any>(`${environment.api}/alterarCliente`, cliente).toPromise()
    return alterar

  }

  async filtraCliente(tipo: Object) {
    const filtro = await this.http.post<any>(`${environment.api}/filtrarClientes`, tipo).toPromise()
    return filtro

  }




  async buscaCnpj(cnpj: any, instace: string) {
    let resultCnpjExterno: any
    if (instace == 'API') {
      resultCnpjExterno = await this.http.get<any>(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`).toPromise()
    } else {
      resultCnpjExterno = await this.http.post<any>(`${environment.api}/apiCnpj`, cnpj).toPromise()

    }
    return resultCnpjExterno
  }


  async buscaCep(link: string) {
    const resultCep = await this.http.get<any>(link).toPromise()
    return resultCep
  }

}
