import { environment } from './../../../../environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import jwtDecode from 'jwt-decode';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public hash = "bjsudbfisabdifuwsdnjcu9eiddihbprjegnf90pjvpsodcuplçwadn";
  public mostrarMenuEmitter = new EventEmitter<boolean>()



  constructor(private http: HttpClient) { }

  async login(userLogin: any) {
    const login = await this.http.post<any>(`${environment.api}/login`, userLogin).toPromise()

    if (login && login.token && login.cpf) {
      window.sessionStorage.setItem('hash', login.cpf)
      window.sessionStorage.setItem(this.hash, login.token)
      window.localStorage.setItem('token', login.token)
      return true
    }
    return false
  }

  getAuthToken() {
    const token: any = window.localStorage.getItem('token')
    const veryToken = window.sessionStorage.getItem(this.hash)

    if (token == veryToken) {
      return token
    }

  }



  isTokenExpiredDate(token?: string): boolean {
    const decode: any = jwtDecode(token)
    if (decode === '') {
      return null
    }

    const date: any = decode.id
    window.localStorage.setItem('usuario', date)
    return date
  }




  isTokenExpired(token?: string): boolean {
    if (!token) {
      return true
    }
    const date: any = this.isTokenExpiredDate(token)
    const veryId = window.sessionStorage.getItem('hash')


    if (date !== veryId) {
      return true
    }


    return false
  }


  isUserLoggedin() {
    const token = this.getAuthToken()
    if (!token) {
      this.mostrarMenuEmitter.emit(false)
      return false
    } else if (this.isTokenExpired(token)) {
      this.mostrarMenuEmitter.emit(false)
      return false
    }
    this.mostrarMenuEmitter.emit(true)
    return true
  }

  async isAdmin() {
    const logado = this.isUserLoggedin()

    if (logado) {
      const usuario = window.localStorage.getItem('usuario')
      const admin = await this.http.post<any>(`${environment.api}/isAdmin`, { usuario }).toPromise()
      if (admin) {
        return true
      }
      return false
    }

    return false

  }


  async recoverPassword(user: { cpf: string; }) {
    const recoverPassword = await this.http.post<any>(`${environment.api}/recoverPassword`, user).toPromise()
    return recoverPassword
  }




}