import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ReportService {
  public report
  constructor(private http:HttpClient) { }

  async checkToken(usuario: string){
    const checkToken = await this.http.post<any>(`${environment.api}/checkToken`, {usuario}).toPromise()
  }

  async relatorioCliente(pais?,status?){
    const relatorioCliente = await this.http.post<any>(`${environment.api}/relatorioCliente`, {pais, status}).toPromise()
    return relatorioCliente
  } 

  async relatorioAtendimento(status?,departamento?,finalizado?){
    const relatorioAtendimento = await this.http.post<any>(`${environment.api}/relatorioAtendimento`, {status,departamento,finalizado}).toPromise()
    return relatorioAtendimento
  }

  async relatorioVendas(usuario?, dataInicial?,dataFinal?){
    const relatorioAtendimento = await this.http.post<any>(`${environment.api}/relatorioVendas`, {usuario, dataInicial, dataFinal}).toPromise()
    return relatorioAtendimento
  } 

  /*******************************************************************/


  async ListarStatus(){
    const listarStatus = await this.http.post<any>(`${environment.api}/listarStatus`, {}).toPromise()
    return listarStatus
  } 


  async ListagensUsuarios(){
    const listagensUsuarios = await this.http.post<any>(`${environment.api}/listarUsuariosRelatorios`, {}).toPromise()
    return listagensUsuarios
  } 

  async infoUsuario(usuario){
    const infoUsuario = await this.http.post<any>(`${environment.api}/infoUsuario`, {usuario}).toPromise()
    return infoUsuario
  } 


  async ListagensDepartamentos(){
    const listagensDepartamentos = await this.http.post<any>(`${environment.api}/listarDepartamentos`, {}).toPromise()
    return listagensDepartamentos
  } 

  async ListarPaises(){
    const listagensPaises = await this.http.post<any>(`${environment.api}/listarPaises`, {}).toPromise()
    return listagensPaises
  } 



}
