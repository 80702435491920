import { Router } from '@angular/router';
import { LoginService } from './../providers/login.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { LoadingService } from 'src/app/shared/loading/loading.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


export class LoginComponent implements OnInit {
  loginFormControl = new FormControl('', [Validators.required]);
  passwordFormControl = new FormControl('', [Validators.required])
  recoverFormControl = new FormControl('', [Validators.required])

  hide = true;


  matcher = new MyErrorStateMatcher();



  public login = {
    login: '',
    password: ''
  }
  public user = {
    cpf: ''
  }
  public checkRecover = false
  public msg = false
  public loading: boolean

  constructor(
    private loginService: LoginService,
    private router: Router,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    localStorage.clear();
    sessionStorage.clear()
  }

  async onSubmit() {
    this.loadingService.loadingState(true, "Logando...")

    try {
      const logar = await this.loginService.login(this.login)
      this.router.navigate([''])

    } catch (error) {
      console.log("Não foi possivel logar")
    }

    this.loadingService.loadingState(false)

  }



  async recoverPassword() {
    this.loadingService.loadingState(true, "Solicitando Alteração...")

    try {
      const novaSenha: any = await this.loginService.recoverPassword(this.user)
      this.checkRecover = false
      this.user.cpf = ''
      if (novaSenha.msg == "SENHA ALTERADA COM SUCESSO!") {
        this.msg = true
      }

    } catch (error) {
      console.log("Não foi possivel alterar a senha")
      this.checkRecover = false
    }

    this.loadingService.loadingState(false)

  }




}
